import { render, staticRenderFns } from "./LessonNav.vue?vue&type=template&id=1622ad77&scoped=true&"
import script from "./LessonNav.vue?vue&type=script&lang=js&"
export * from "./LessonNav.vue?vue&type=script&lang=js&"
import style0 from "./LessonNav.vue?vue&type=style&index=0&id=1622ad77&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1622ad77",
  null
  
)

export default component.exports